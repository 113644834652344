import React from "react"
import { Helmet } from "react-helmet"

class HeaderTitle extends React.Component{
    render(){
        const {title} = this.props
        return(
            <div> 
            <Helmet htmlAttributes={{ lang : 'en' }}>
               <title>Foretheta | {title}</title>
            </Helmet>
            </div>
        )
    }
}

export default HeaderTitle