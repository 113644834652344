import React from "react"
import { CardTitle, Card, CardBody, CardImg, CardText } from "reactstrap"

class ClientFeedback extends React.Component {
    render() {
      const {name, feedback, image, alternate} = this.props
  
      return (
        <Card className="py-4 h-100">
          <CardImg src={image} alt={alternate} className="image mx-auto rounded-circle"/>
          <CardBody className="mb-3 font-italic">
            <CardTitle className="h4 font-weight-bold mb-4">{name}</CardTitle>
            <CardText className="px-0">{feedback}</CardText>
          </CardBody>
        </Card>
      )
    }
  }
  export default ClientFeedback