import React from "react"
import { CardTitle, Card, CardBody, CardText, Col } from "reactstrap"
import { Link } from "gatsby"

const CaseStudy = ({ number, description, link }) => {
  return (
    <Col md={3} sm={6} xs={12} className="px-0 mb-3">
      <Card className="py-3 mx-2">
        <CardBody>
          <CardTitle className="mb-5">
            <h4>{number}</h4>
          </CardTitle>
          <CardText className="px-0">
            <span className="d-block">{description}</span>
            <span className="mt-4">
            <Link to={link} aria-label="Click to read discription about the case study" >Read more...</Link>
            </span>
          </CardText>
        </CardBody>
      </Card>
    </Col>
  )
}

export default CaseStudy
