import React from "react"
import HelmetTitle from "../components/utils"
import Reed from "../images/Reed.webp"
import Shawn from "../images/shawn.jpg"
import { Button, CardDeck, Row, Col } from "reactstrap"
import icon1 from "../images/icon1.png"
import icon2 from "../images/icon2.png"
import icon3 from "../images/icon3.png"
import ClientFeedback from "../components/Index/ClientFeedback"
import CaseStudy from "../components/Index/CaseStudy"
import Banner from "../components/Index/Banner"
import Layout from "../components/Layout"

const IndexPage = () => {
  const tagline = "We accelerate your journey from concept to reality",
    title = `Today, business and technology are indistinguishable. Keeping pace with the emerging 
        technology landscape can be difficult for even the most tech-savvy leaders. We can help.`,
    buttonText = "Let's Talk",
    buttonURL = "https://calendly.com/foretheta"

  return (
    <Layout>
      <div className="landing mb-6">
        <HelmetTitle title="Home" />
        <div className="ft-banner pt-6 px-4 mb-5 text-center">
          <h1 className="mb-5 font-weight-bold">{tagline} </h1>
          <h3 className="mb-5">{title}</h3>
          <div className="cell large-offset-2 pb-6 pb-sm-5">
            <Button
              onClick={() => {
                window.location = buttonURL
              }}
            >
              {buttonText}
            </Button>
          </div>

          <CardDeck className="big-font d-block d-lg-flex">
            <Row>
              <Col md={4} sm={6} xs={12} className="px-md-0 mx-md-0 mb-5">
                <Banner
                  image={icon1}
                  tagline="Redesign experiences to create new sources of value."
                  alternate="Redesign experiences"
                />
              </Col>
              <Col md={4} sm={6} xs={12} className="px-md-0 mx-md-0 mb-5">
                <Banner
                  image={icon2}
                  tagline="Build cloud-native apps to modernize your operations."
                  alternate="Build cloud-native apps"
                />
              </Col>
              <Col md={4} sm={{ size: 6, offset: 3 }} xs={12} className="px-md-0 mx-md-0 small-offset-3 mb-5">
                <Banner
                  image={icon3}
                  tagline="Build simple web-based tools to streamline internal workflows."
                  alternate="Build simple web-based tools"
                />
              </Col>
            </Row>
          </CardDeck>
        </div>

        <div className="text-center px-xl-6 px-lg-5 px-md-4 px-sm-2  pb-6 pt-md-5 mt-5 px-xs-1" id="services">
          <h1 className="text-center font-weight-bold mb-5 mt-5">Tech consulting services</h1>
          <p className="mb-4 big-font">
            For business owners who already have too much on their plate: save yourself time, bandwidth, and hassle by
            letting the pros do it for you.
          </p>
          <p className="big-font">
            Every business is unique. We pick the right tools for your business and deliver quality work. We strive to
            produce maintainable codebases that have a low cost of ownership.
          </p>
        </div>

        <div className="text-center ft-banner-filled px-xl-6 px-lg-5 px-md-4 px-sm-2 py-5 px-xs-1" id="about">
          <h1 className="text-capitalize font-weight-bold mb-5 mt-3">why foretheta?</h1>
          <p className="mb-4 big-font">
            As business owners ourselves, we understand that running a successful business entails investing in the
            right resources for the right purposes. In exchange for your time and trust, we give you an effective and
            expert technology team that does what they say they are going to do. No fluff, no gimmicks.
          </p>
          <p className="mb-4 big-font">
            We work with smart clients who understand that it's the customer, not org-charts or office politics, who
            matters most. These clients are hard to find, so when we get them we treat them well. Sounds good?
            <a className="contact" href="/contact">
              &nbsp;Then get in touch.
            </a>
          </p>
        </div>

        <div className="text-center  px-xl-6 px-lg-5 px-md-4 px-sm-2 py-5 mx-0 px-xs-1" id="portfolio">
          <h1 className="font-weight-bold mb-5">Our work</h1>
          <p className="mb-4 big-font">
            We help our clients solve their business problems by approaching every challenge with fresh eyes. We work
            hard to truly understand issues and resolve them through technology, delighting your customers and your
            providing value to you.
          </p>
          <p className="font-weight-bold big-font">But that’s enough talk. We think our work will speak for itself.</p>
        </div>

        <CardDeck className="text-center portfolio px-3 mx-0">
          <Row className="mb-5">
            <CaseStudy
              number="Case Study 1"
              description="Scaling and streamlining complex cloud infrastrucure, faster and at a greater scale."
              link="/our-work/#case1"
            />
            <CaseStudy
              number="Case Study 2"
              description="Driving greater understanding through fast Text analytics of more than 100 Million documents."
              link="/our-work/#case2"
            />
            <CaseStudy
              number="Case Study 3"
              description="Aligning remote workforce to work towards the right business goals by replacing slow legacy tools."
              link="/our-work/#case3"
            />
            <CaseStudy
              number="Case Study 4"
              description="Validating an algorithmic trading strategy and enabled to run it on live and historical data."
              link="/our-work/#case4"
            />
          </Row>
        </CardDeck>

        <div className="text-center pt-3 pb-2 mb-5 mt-4" id="testimonials">
          <h2 className="font-weight-bold">What our clients say</h2>
        </div>

        <CardDeck className="text-center px-5 mb-5 testimonials ">
          <Row>
            <Col md={6} sm={12} xs={12} className="mb-5">
              <ClientFeedback
                name="Reed Jessen, BCG"
                feedback="Fantastic service. Foretheta helped us in our data science project. They did incredible work in
            making our application run faster and with fewer resources. They also have exellent support that always
            keeps us informed."
                image={Reed}
                alternate="picture of Reed jessen, BCG"
              />
            </Col>
            {/* <Col md={4} sm={6} xs={12} className="mb-5">
            <ClientFeedback name="Rachel Mann, AtlasStaffing" 
            feedback="I'm very happy with Foretheta.They built an extremely intuitive and user-friendly web application.
            I liked their professionalism, work quality, and flexibility. I would strongly recommend!"
            image={index}/>
          </Col> */}
            <Col md={{ size: 6, offset: 0 }} sm={{ size: 12, offset: 0 }} xs={12} className="mb-5">
              <ClientFeedback
                name="Shawn Ambwani, Unified Patents"
                feedback="We needed a competent and reliable team to build APIs and integrate them into our
                  existing web application. They listened to our requirements and offered an efficient build
                  and integration roadmap. I was thrilled with the service we received."
                image={Shawn}
                alternate="picture of Shawn Ambwani, Unified Patents"
              />
            </Col>
          </Row>
        </CardDeck>
      </div>
    </Layout>
  )
}

export default IndexPage
