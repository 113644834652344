import React from "react"
import { Card, CardImg, CardBody, CardText } from "reactstrap"

class Banner extends React.Component {

  render() {
    const { tagline, image, alternate } = this.props
    return (
      <Card className="pt-4 mb-3 h-100">
        <CardImg src={image} alt={alternate} className="image mx-auto image-banner" />
        <CardBody className="mb-3">
          <CardText className="px-0">{tagline}</CardText>
        </CardBody>
      </Card>
    )
  }
}
export default Banner
